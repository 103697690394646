@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/maps";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/root";
@import "../node_modules/bootstrap/scss/forms";

* {

  &,
  * {
    box-sizing: border-box;
  }
}

hr {
  margin: 1rem 0;
  color: rgb(33, 37, 41);
  background-color: currentColor;
  border: 0;
  opacity: .25;

  &:not([size]) {
    height: 1px;
  }
}

.psp{
  $blue: #63cae9;
  $green:  #94c424;
  .card-header{
    background: $blue!important;
  }
  .btn{
    background: $green;
  }
}

.ssm{
  .card-header{
    font-weight: 900;
  }
}

/* login */
.form-signin {
  width: 770px;
  max-width: 100%;
  padding: 15px;
  margin: auto;
  text-align: center;

  input[type="email"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: .3rem;
  }

  .card-body {
    flex: 1 1 auto;
    padding: 1rem 1rem;
  }

  .card-header {
    background-color: rgb(65, 126, 239);
    color: white;
    text-align: center;
    font-size: 1.5rem;
    padding: 1rem 0;

    h4,
    strong {
      margin: 0 auto;
      color: #fff;
      font-size: 1.5rem;
      font-weight: 600;
    }
  }
}

.form-floating {
  margin: 30px 9% 30px 9%;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}


.form-signin input[type="order-id"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.form-signin button {
  margin: 20px 0;
  background-color: rgb(248, 143, 30);
  color: white;
  border: 0;
  width: 100%;
  padding: .5rem 1rem;
  font-size: 1.25rem;
  border-radius: .3rem;
}


.form-signin .loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  /* background: rgba(255,255,255,0.9); */
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  z-index: 9999;
  text-align: center;

  &,
  * {
    color: white;
  }
}

.form-signin .cover-spin {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  /* background-color: rgba(255,255,255,0.7); */
  z-index: 9999;
}

.form-signin .cover-spin::after {
  content: '';
  display: block;
  position: absolute;
  left: 49%;
  top: 40%;
  width: 40px;
  height: 40px;
  border-style: solid;
  border-color: white;
  border-top-color: transparent;
  border-width: 4px;
  border-radius: 50%;
  -webkit-animation: spin .8s linear infinite;
  animation: spin .8s linear infinite;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* order */
.order-container {
  max-width: 1320px;
  margin: 30px auto;
  background: white;
  border: 1px solid #edecec;
  border-radius: 15px;
  padding: 15px;

  @media screen and (min-width: 768px) {
    padding: 30px;
  }

  .order-header {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    @media screen and (min-width: 768px) {
      grid-template-columns: 3fr 1fr;
    }
  }

  h1 {
    color: #41413b;
    font-size: 25px;
    font-weight: bold;
  }

  .row {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    flex-flow: wrap;
    margin-bottom: 1rem;
  }

  .status-wrapper {
    text-align: center;
    width: 100%;
  }

  .title-wrapper {
    width: 100%;
    text-align: center;

    @media screen and (min-width: 768px) {
      text-align: left;
      margin-bottom: 0;
    }
  }

  .date-wrapper {
    width: 100%;
    text-align: center;
    font-size: 16px;

    @media screen and (min-width: 768px) {
      grid-column: 2;
    }
  }

  .themed-grid-col {
    padding-top: .75rem;
    padding-bottom: .75rem;
    color: white;
    /* background-color: #0d6efd; */
  }

  .order {
    color: rgb(65, 126, 239);
    font-size: 24px;
  }

  .status-0,
  .status-1,
  .status-6,
  .status-12,
  .status-13 {
    background: #A9A9A9;
  }

  .status-2 {
    background: #59ac1f;
  }

  .status-3 {
    background: #417eef;
  }

  .status-4 {
    background: #585857;
  }

  .status-5 {
    background: #585857;
  }

  .status-7 {
    background: #f88f1e;
  }

  .status-8 {
    background: #417eef;
  }

  .status-9 {
    background: #ee4d41;
  }

  .status-10 {
    background: #59ac1f;
  }

  .status-11 {
    background: #ee4d41;
  }

  .status-14 {
    background: #585857;
  }

  .header {
    background: #edecec;
    border: 2px solid white;
    position: relative;
    z-index: unset;
  }

  a {
    text-decoration: none;
    color: rgb(65, 126, 239)
  }

  a:hover {
    text-decoration: underline;
  }

  .tracking-number {
    color: rgb(248, 143, 30);
  }

  .tracking-number:hover {
    color: rgb(248, 143, 30);
  }
}


.table-header {
  [class*="col-"] {
    width: 66.66667%;

    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    &:last-of-type {
      width: 33.3333%;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
}




.awaiting-shipment-container .column-value {
  color: #59ac1f;
  font-size: 16px;
}

/* item */
.item {
  display: flex;
  flex-flow: row nowrap;
  padding: 15px 0;
  width: 100%;
}

.item:not(:last-child) {
  border-bottom: 1px solid #eee;
}

.item-image img {
  max-width: 100%;
  height: auto;
}

.item .item-info {
  display: flex;
  justify-content: space-between;
  padding-left: 15px;

  .item-details {
    a {
      font-size: 16px;
    }

    ul {
      list-style: none;
      padding-left: 0px;
      padding-top: 10px;

      li {
        font-size: 14px;
        color: #424242;
      }
    }
  }

}

.tooltip__wrapper {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: 300ms;

  &:hover {
    transition: 100ms;
    cursor: pointer;
    color: rgb(65, 126, 239);
  }
}

.est-tooltip {
  max-width: 200px;
  position: absolute;
  top: -10px;
  right: 0;
  display: block;
  width: 200px;
  transform: translateY(-100%);
  background: #333;
  color: #fff;
  border-radius: 3px;
  padding: 0.25em;  

  &:after {
    content: '';
    width: 0;
    height: 0;
    display: block;
    border-width: 5px;
    border-style: solid;
    border-color: #333 transparent transparent transparent;
    position: absolute;
    right: 0.6em;
    bottom: 0;
    transform: translateY(100%);
  }
}

/* media queries */
@media only screen and (min-width: 768px) {
  .text-center-desktop {
    text-align: center;
  }

  .item-image {
    flex: 0 1 16%;
  }

  .item .item-info {
    flex: 0 1 84%;
    flex-flow: row nowrap;
  }

  .item-info .item-details {
    flex: 0 1 59%;
  }

  .item-qty,
  .est-ship-date {
    text-align: center;
    flex: 0 1 39%;
    font-weight: bold;
  }
}

@media only screen and (max-width: 767px) {
  .hide-size-mobile {
    display: none !important;
  }

  .form-floating {
    margin-left: 20px;
    margin-right: 20px;
  }

  .item-image {
    flex: 0 1 25%;
  }

  .item .item-info {
    flex-flow: row wrap;
    flex: 0 1 75%;
  }

  .item-info>* {
    flex: 1 1 100%;
  }

  .est-ship-date,
  .item-qty {
    font-size: 14px;

    &:before {
      content: "Est. Ship Date: ";
      font-weight: bold;

    }
  }

  .item-qty:before {
    content: "Qty Shipped: ";
  }

}